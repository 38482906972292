import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';

import { colors } from '../../theme/colors';

type Props = {
  category: string;
  title: string;
  children?: ReactNode;
};

export const ArticleHeading: FC<Props> = ({ category, title, children }) => {
  return (
    <div css={ArticleHeadingStyle}>
      <div css={ArticleHeadingWrapperStyle}>
        <div css={HeadingWrapperStyle}>
          <p css={CategoryStyle}>{category}</p>
          <h1 css={TitleStyle}>{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
};

const ArticleHeadingStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const ArticleHeadingWrapperStyle = css({
  position: 'relative',
  width: 2302,

  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 60,
  gap: 40,

  '@media(max-width: 1119px)': {
    paddingTop: 40,
  },
});

const HeadingWrapperStyle = css({
  width: '100%',
  maxWidth: 1120,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    gap: 16,
    padding: '0 14px',
  },
});

const CategoryStyle = css({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1,
  color: colors.primary,

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const TitleStyle = css({
  fontSize: 40,
  fontWeight: 700,
  color: colors.black,
  marginBottom: 12,
  whiteSpace: 'pre-wrap',

  '@media(max-width: 1119px)': {
    fontSize: 28,
    marginBottom: 6,
  },
});
