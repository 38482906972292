import { css } from '@emotion/react';
import { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleHeading } from '../../components/organisms/ArticleHeading';
import { colors } from '../../theme/colors';
import { OptimizationFlows } from '../../components/organisms/OptimizationFlows';
import { Link } from 'gatsby';

export const Head = () => (
  <HeadInner
    path='/feature/invoice/'
    title='インボイス制度・電子帳簿保存法に対応した法人カード「paild（ペイルド）」'
    description='paild（ペイルド）の機能です。カード発行、利用制限、通知機能、AI-OCR、領収書管理、仕訳、会計ソフト連携、電子帳簿保存法、インボイス制度、豊富なサポート体制'
  />
);

type ListWrapperProps = {
  title: string;
  list: string[];
  importantIndexList?: number[];
};

const ListWrapper: FC<ListWrapperProps> = ({
  title,
  list,
  importantIndexList,
}) => {
  return (
    <div css={ListWrapperStyle}>
      <h3 css={SectionSubHeadingStyle}>{title}</h3>
      <ul>
        {list.map((el, index) => {
          return (
            <li
              key={index}
              css={ListElementStyle(
                importantIndexList && importantIndexList.includes(index)
              )}
            >
              {el}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const InvoicePage = () => {
  const links = [
    {
      name: '法人支出の最適化を実現する「paild（ペイルド）」 インボイス制度・電子帳簿保存法に対応',
      path: '/feature/invoice/',
    },
  ];

  return (
    <Layout links={links}>
      <ArticleHeading
        category='invoice'
        title={
          '法人支出の最適化を実現する「paild（ペイルド）」\nインボイス制度・電子帳簿保存法に対応'
        }
      />

      <article css={ArticleStyle}>
        <StaticImage
          src='../../images/feature/invoice_thumbnail.png'
          alt='AI-OCR'
          quality={100}
          placeholder='none'
          objectFit='contain'
          css={{
            maxWidth: 960,
            borderRadius: 16,
            alignSelf: 'center',
          }}
        />
        <section css={SectionStyle}>
          <h2 css={SectionHeadingStyle}>インボイス制度</h2>
          <p css={textStyle}>
            2023年10月1日から開始するインボイス制度（適格請求書等保存方式）とは、複数税率に対応した消費税の仕入税額控除の方式です。制度の導入後は仕入税額控除を受けるために、買手となる事業者側は記載要件を満たした適格請求書等の受領・受領した適格請求書等の保存・一定の事項を記載した帳簿の保存など多くの作業が発生します。
          </p>
          <ListWrapper
            title='必要な対応'
            list={[
              '適格請求書発行事業者登録番号（以下「登録番号」）の保存',
              '支払先が適格請求書発行事業者（以下「登録事業者」）か国税庁のサイトで確認',
              '支払先が要件を満たしてない場合、税区分での管理が必要',
            ]}
          />
          <ListWrapper
            title='paildで対応するメリット'
            list={['納税額が少なくなる', '登録事業者の自動判定']}
          />
        </section>
        <section css={SectionStyle}>
          <h2 css={SectionHeadingStyle}>電子帳簿保存法</h2>
          <p css={textStyle}>
            電子帳簿保存法とは、国税関係（法人税法や所得税法）の帳簿や書類を電磁的記録（電子データ）で保存することを認める法律です。令和5年度税制改正大綱では要件の緩和が多く、電子帳簿保存法に対応しやすくなりましたが、3つの区分の内「電子取引データ保存」は2024年1月1日以後にやり取りする電子取引データについて適用されます。領収書などの電子データを送付・受領した場合には、その電子データを一定の要件を満たした形で保存する必要があります。
          </p>
          <ListWrapper
            title='電子帳簿保存法で扱う3つの区分'
            list={[
              '電子帳簿等保存：電子的に作成した帳簿・書類をデータのまま保存',
              'スキャナ保存：紙で受領・作成した書類をスキャン文書で保存',
              '電子取引データ保存：電子データで受け取る取引情報をデータで保存',
            ]}
            importantIndexList={[2]}
          />
          <ListWrapper
            title='保存要件'
            list={[
              '改ざん防止のための措置をとる',
              '日付、金額、取引先で検索できる',
            ]}
          />
          <ListWrapper
            title='paildで対応するメリット'
            list={[
              '領収書の原本の保管が不要',
              '紙のファイリング作業や、必要な書類を探す手間を削減',
              '領収書提出のために郵送や出社が不要',
            ]}
          />
          <p css={textStyle}>
            法人支出の最適化を実現する「paild（ペイルド）」はインボイス制度や電子帳簿保存法に準拠するとともに、経費精算や小口現金にまつわる業務を効率化します。
          </p>
        </section>
        <section css={SectionStyle}>
          <h2 css={SectionHeadingStyle}>
            paildなら決済・領収書の回収・仕訳までスムーズに
          </h2>
          <p css={textStyle}>
            2020年8月にリリースした法人支出の最適化を実現する「paild（ペイルド）」は、オンライン上で簡単に法人カードを発行・管理できる新しいサービスです。従業員や拠点に安心して配布できるため、経費の立替や、小口現金の管理が不要になります。また、利用明細の管理画面への反映、管理者への通知はすべてリアルタイムで行われるため、ガバナンスの強化に繋がります。
          </p>
          <p css={textStyle}>
            カード決済から、領収書提出、仕訳までスピーディーに行えるのがpaildならでは。すぐに領収書を回収することで紛失を減らし、月末月初に集中する経理業務を分散することができます。
          </p>
          <div css={FlowWrapper}>
            <OptimizationFlows />
          </div>
        </section>
        <section css={SectionStyle}>
          <h2 css={SectionHeadingStyle}>paildの特徴</h2>
          <h3 css={SectionSubHeadingStyle}>領収書の回収・管理が簡単</h3>
          <p css={textStyle}>
            電子帳簿保存法対応のため領収書やレシートの紙での保存は不要です。領収書はスマートフォンで写真に撮り、領収書提出依頼メールに添付して返信するだけでアップロードできるため、カード決済した従業員はその場で簡単に領収書を提出できます。また、カードの利用明細と領収書は自動で紐付けられ、照合作業の工数削減に繋がります。
          </p>
          <div css={JiimaWrapper}>
            <div css={JiimaLogoWrapper}>
              <StaticImage
                src='../../images/top-view/電子取引ソフト【R3】黒L.jpg'
                alt='電子取引ソフト'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={JiimaLogoStyle}
              />
              <StaticImage
                src='../../images/top-view/スキャナ保存ソフト【R3】黒L.jpg'
                alt='スキャナ保存ソフト'
                quality={100}
                placeholder='none'
                objectFit='contain'
                css={JiimaLogoStyle}
              />
            </div>

            <p>
              電子取引ソフト法的要件認証」の認証ロゴは公益社団法人
              日本文書情報マネジメント協会によりライセンスされています。
            </p>
          </div>
          <h3 css={SectionSubHeadingStyle}>AI-OCRにより登録事業者を自動判定</h3>
          <p css={textStyle}>
            領収書に記載されている登録番号をAI-OCRで読み取り、自動で国税庁のデータベースを照会し、登録事業者か判定を行います。また、登録事業者としての判定が出来なかった場合、管理画面にアラートが表示されるため、ひと目で把握することができます。
          </p>
          <StaticImage
            src='../../images/feature/20230830_invoice_AI-OCR.png'
            alt='AI-OCR'
            quality={100}
            placeholder='none'
            objectFit='contain'
            css={{
              maxWidth: 960,
              boxShadow: '0px 1.8px 8.1px 0px #00000040',
              borderRadius: 16,
              alignSelf: 'center',
            }}
          />
          <h3 css={SectionSubHeadingStyle}>
            paild上で自動仕訳が可能 ※2023年10月頃にリリース予定
          </h3>
          <p css={textStyle}>
            領収書に記載の金額、税区分、勘定科目など会計処理に必要な情報が自動で登録されます。また、領収書の発行日や金額とカード明細のデータを照らし合わせることで、誤った領収書が添付されていないか自動検知することができます。カード利用者は領収書をアップロードするだけで、自然とインボイス制度に対応した運用ができるようになり、現場に負担なくインボイス制度に対応することが可能です。さらに、paildで仕訳した明細情報は、お使いの会計ソフトに合わせてCSVの出力形式をカスタマイズできます。
          </p>
          <h3 css={SectionSubHeadingStyle}>3分でわかるpaildサービス資料</h3>
          <div>
            <p css={textStyle}>
              paildは法人支出における課題を解決する、今までと全く違う発想で作られたサービスです。本資料では、paildでできることや、導入事例、機能などを説明していますので、ぜひご覧ください。
            </p>
            <Link css={LinkStyle} to='/request-document/'>
              サービス資料のダウンロード
            </Link>
          </div>
        </section>
      </article>
    </Layout>
  );
};

const ArticleStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 60,

  '@media(max-width: 1119px)': {
    padding: '0 17px',
    maxWidth: 640,
  },

  '@media(max-width: 768px)': {
    gap: 50,
  },
});

const SectionStyle = css({
  width: '100%',
  maxWidth: 1120,
  display: 'flex',
  flexDirection: 'column',
  gap: 32,

  '@media(max-width: 768px)': {
    gap: 12,
  },
});

const SectionHeadingStyle = css({
  fontSize: 34,
  color: colors.primary,
  fontWeight: 700,

  '@media(max-width: 1119px)': {
    fontSize: 24,
  },
});

const textStyle = css({
  fontSize: 16,
  color: colors.black,

  br: { display: 'block', content: '""', margin: '8px 0' },

  '@media(max-width: 1119px)': {
    fontSize: 14,
  },
});

const SectionSubHeadingStyle = css({
  fontSize: 18,
  color: colors.primary,
  fontWeight: 700,

  '@media(max-width: 1119px)': {
    fontSize: 16,
  },
});

const ListWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const ListElementStyle = (isImportant?: boolean) =>
  css({
    fontSize: 16,
    marginLeft: 24,
    fontWeight: isImportant ? 700 : 400,

    '&::marker': {
      color: colors.primary,
    },

    '@media(max-width: 1119px)': {
      fontSize: 14,
    },
  });

const FlowWrapper = css({
  padding: 32,
  background: colors.secondary,
  borderRadius: 24,

  '@media(max-width: 1119px)': {
    padding: '32px 20px',
  },

  '@media(max-width: 768px)': {
    display: 'none',
  },
});

const JiimaWrapper = css({
  display: 'flex',
  gap: 16,
  alignItems: 'center',

  p: {
    color: colors.gray,
    fontSize: 12,
  },

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
  },
});

const JiimaLogoWrapper = css({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 256,
});

const JiimaLogoStyle = css({
  width: '100%',
  maxWidth: 80,
});

const LinkStyle = css({
  color: colors.primary,
  textDecoration: 'underline',
  fontWeight: 500,

  '&:hover': {
    color: colors.black,
  },
});

export default InvoicePage;
